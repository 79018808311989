import React, { useState } from "react";

import {
	NavLink,
	useLocation,
} from "react-router-dom";

import {
	IoLogoInstagram,
	IoLogoFacebook,
	IoMdLogOut,
	IoIosImages,
	IoMdImages
} from "react-icons/io";

import { SiAboutDotMe } from "react-icons/si";
//import { GiMountaintop } from "react-icons/gi";
import { GrClose } from "react-icons/gr";

import { useAppSelector, useAppDispatch } from "../../store/hooks";
import { selectUser, logout } from "../../store/reducers/user";

import './index.css';
import API from "../../utils/API";
import { FaCog } from "react-icons/fa";
import useWindowDimensions from "../../utils/Window";
import { BiExpand, BiMessageDetail } from "react-icons/bi";

interface NavigationProps {
	className?: string;
};

const Navigation = ({ className }: NavigationProps) => {

	const [open, setOpen] = useState(false);
	const user = useAppSelector(selectUser);
	const dispatch = useAppDispatch();
	const location = useLocation();
	const path_parts = location.pathname.substring(1).split("/");

	const { width } = useWindowDimensions();

	const portfolio_open = path_parts.length === 3 &&
		path_parts[0] === 'portfolio' &&
		path_parts[2] !== 'all';

	const requestLogout = () => {
		API.post('/logout').then(response => {
			dispatch(logout());
		}).catch(err => {

		})
	}

	const mobile = width < 827;
	const collapsed = (portfolio_open || mobile) && !open;

	return (
		<div className={`navigation ${className} ${collapsed ? 'collapsed' : ''} ${open ? 'open' : ''}`}>
			<div className="logo">
				<img src={`/${collapsed ? 'logo_icon.png' : 'logo_black.png'}`} 					
					alt="Ernesto Lozano Fotography" />
			</div>
			{ !open && mobile && 
				<button className="link"
						onClick={e=> setOpen(true)}>
					<BiExpand />
				</button>
			}
			{ open &&
				<button className="close"
					onClick={e => setOpen(false)}>
					<GrClose />
				</button>
			}
			<div className="links">
				<ul className="nav-list">
					<li>
						<NavLink exact to="/about"
								onClick={e => mobile && setOpen(false)}>
							<SiAboutDotMe /><span>About</span>
						</NavLink>
					</li>
					<li>
						<NavLink exact to="/portfolio/all">
							<IoIosImages /> <span>Portfolio</span>
						</NavLink>
					</li>{/*
					<li>
						<NavLink exact to="/packages/all">
							<BiCollection /><span>Packages</span>
						</NavLink>
					</li> */}
					<li>
						<NavLink exact to="/contact">
							<BiMessageDetail /><span>Contact</span>
						</NavLink>
					</li>
					{user !== null &&
						<>
							<li>
								<NavLink exact to="/images">
									<IoMdImages /><span>Images</span>
								</NavLink>
							</li>
							<li>
								<NavLink exact to="/settings">
									<FaCog /><span>Settings</span>
								</NavLink>
							</li>
						</>
					}
				</ul>
			</div>
			<div className="social">
				<ul className="social-list">
					<li>
						<a target="_blank"
							rel="noreferrer"
							href="https://instagram.com/ernestolozanophotography">
							<IoLogoInstagram />
						</a>
					</li>
					<li>
						<a target="_blank"
							rel="noreferrer"
							href="https://www.facebook.com/ErnestoLozanoPhotography">
							<IoLogoFacebook />
						</a>
					</li>
					{user !== null &&
						<li><button onClick={e => requestLogout()}><IoMdLogOut /></button></li>}
				</ul>
			</div>
		</div>
	);
};

export default Navigation;
