import React from "react";
import Loader from "react-spinners/BounceLoader";

import './index.css';

interface LoadingProps {
    color?: string;
    overlay?: boolean;
    icon?: React.ReactNode | null;
    label?: string | null;
}

const Loading = ({
    color = "#191f1f",
    overlay = false,
    icon = null,
    label = null,
}: LoadingProps) => {


    return (
        <div className={`loading ${overlay ? 'overlay' : ''}`}>
            { icon &&
                <div className="icon">
                    {icon}
                </div>
            }
            { label &&
                <div className="label">
                    <h4>{label}</h4>
                </div>
            }
            <div className={`loader ${ (label || icon) ? 'backer' : ''}`}>
                <Loader color={color}
                        size={(label || icon) ? 200 : undefined} />
            </div>
        </div>
    );
};

export default Loading;
