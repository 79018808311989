import React, { useState, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import ClipLoader from "react-spinners/ClipLoader";
//import LazyLoad  from 'react-lazyload';

import './index.css';

export interface Slide {
	src: string,
	video: boolean
};

export interface MediaSlidesProps {
	slides: Slide[];
	className?: string;
};

const MediaSlides = ({ slides, className }: MediaSlidesProps) => {

	const [current, setCurrent] = useState(0);
	const [videoDone, setVideoDone] = useState(false);

	const [isLoading, setIsLoading] = useState(true);

	useEffect(() => {
		const cacheImages = async (images: Array<Slide>) => {
			const promises = await images
				.map(src => new Promise<void>((resolve, reject) => {
					const image: HTMLImageElement = new Image();
					if (src.video) {
						image.src = '/video.jpg';
					} else {
						image.src = src.src;
					}
					image.onload = (e) => resolve();
					image.onerror = (e) => reject();

				}));

			try {
				await Promise.all(promises);
			} catch (err) {
				console.log("Bithes");
				console.log(err);
			}

			setIsLoading(false);
		};

		cacheImages(slides);
	}, [slides]);

	useEffect(() => {
		if (slides.length === 1)
			return;

		if (slides[current].video) {
			if (videoDone) {
				if (current === slides.length - 1)
					setCurrent(0);
				else
					setCurrent(current + 1);
				setVideoDone(false);
			}
			return;
		}

		const timer = setTimeout(() => {
			if (current === slides.length - 1)
				setCurrent(0);
			else
				setCurrent(current + 1);
		}, 5000);
		return () => clearTimeout(timer);
	}, [current, setCurrent, slides.length, slides, videoDone]);



	const variants_video = {
		enter: {
			x: 300,
			opacity: 0
		},
		center: {
			zIndex: 1,
			x: 0,
			opacity: 1
		},
		exit: {
			zIndex: 0,
			x: -300,
			opacity: 0
		}
	};

	const variants_image = {
		enter: {
			x: 300,
			opacity: 0,
			objectPosition: '10%'
		},
		center: {
			x: 0,
			opacity: 1,
			objectPosition: '90%'
		},
		exit: {
			x: -300,
			opacity: 0,

		}
	};


	if (isLoading)
		return <div className='loading-images' >
			<ClipLoader size={300} />
		</div>;

	return (
		<div className={`media-slides ${className}`}>
			<AnimatePresence>
				{slides[current].video ?
					<motion.video src={slides[current].src}
						loop={(slides.length === 1) ? true : false}
						key={slides[current].src}
						initial="enter"
						animate="center"
						exit="exit"
						variants={variants_video}
						autoPlay
						muted
						className="media"
						transition={{
							x: { type: "spring", stiffness: 300, damping: 30 },
							opacity: { duration: 0.2 }
						}}
						poster='/video.jpg'
						onEnded={(e) => setVideoDone(true)} />
					:
					<motion.img src={slides[current].src}
						className="media"
						key={slides[current].src}
						initial="enter"
						animate="center"
						exit="exit"
						variants={variants_image}
						transition={{
							x: { type: "spring", stiffness: 300, damping: 30 },
							opacity: { duration: 0.2 },
							objectPosition: { duration: 5 }
						}}
					/>
				}
			</AnimatePresence>
		</div>
	);
};

export default MediaSlides;
