import React, { useEffect, useState } from 'react';

import {
	BrowserRouter as Router,
	Switch,
	Route,
	Redirect,
	RouteProps,
} from "react-router-dom";

import {
	useAppSelector,
	useAppDispatch
} from "../../store/hooks";

import {
	selectUser,
	getUser
} from "../../store/reducers/user";

import Navigation from '../Navigation';
import About from '../About';
import Experiences from '../Experiences';
import Portfolio, { PortfolioSlide } from '../Portfolio';
import Packages from '../Packages';
import Login from '../Login';


import './index.css';
import ImageUpload from '../ImageUpload';
import ImageAdmin from '../ImageAdmin';
import Contact from '../Contact';
import Lord from '../Lord';
import UserSettings from '../UserSettings';
import Loading from '../Loading';
import API from '../../utils/API';



interface component {
	public: boolean;
}

const componentTemplate: component = {
	public: true
}

function App() {

	const dispatch = useAppDispatch();
	const [fetchUser, setFetchUser] = useState(true);
	const [fetch, setFetch] = useState(true);

	const [attempts, setAttempts] = useState(0);

	const [loading, setLoading] = useState(true);
	const [component, setComponent] = useState<component>(componentTemplate)

	useEffect(() => {
		if (fetchUser) {
			setFetchUser(false);
			dispatch(getUser());
		}
	}, [fetchUser, dispatch])

	useEffect(() => {
		if (fetch) {
			setLoading(true);
			setFetch(false);
			setAttempts(at => at + 1)
			API.get(`/api/site_content/home`, {
				withCredentials: false
			}).then(response => {
				if (response.data.content) {
					setComponent(response.data.content.component);
				}
				setLoading(false);
			}).catch(error => {
				if (attempts < 5)
					setFetch(true);
				else {
					console.log(error.toJSON());
					setLoading(false);
				}
			});
		}
	}, [attempts, fetch])

	if (loading)
		return <Loading />

	return (
		<Router>
			<div className="site-content">
				<Navigation className="nav" />
				<div className="main-content">
					{/* <Header /> */}
					<Switch>
						<Route exact path="/">
							{!component.public ?
								<div className="site-content">
									<div className="coming-soon">
										<p>coming soon</p>
									</div>
								</div>
								:
								<Redirect to='/about' />
							}
						</Route>
						<Route exact path="/about">
							<About />
						</Route>
						<Route path="/experiences"
							component={Experiences} />
						<Route exact path="/portfolio">
							<Redirect to="/portfolio/all" />
						</Route>
						<Route exact path="/portfolio/:entryId/:imageId">
							<PortfolioSlide />
						</Route>
						<Route path="/portfolio/:id">
							<Portfolio />
						</Route>
						<Route path="/contact">
							<Contact />
						</Route>
						<Route exact path="/packages">
							<Redirect to="/packages/all" />
						</Route>
						<Route path="/packages">
							<Packages />
						</Route>
						<Route exact path="/login"
							component={Login} />
						<PrivateRoute exact path="/images">
							<ImageAdmin />
						</PrivateRoute>
						<PrivateRoute path="/images/upload">
							<ImageUpload />
						</PrivateRoute>
						<PrivateRoute path="/lord">
							<Lord />
						</PrivateRoute>
						<PrivateRoute path="/settings">
							<UserSettings />
						</PrivateRoute>
					</Switch>
				</div>
			</div>
		</Router>
	);
}

const PrivateRoute: React.FC<RouteProps> = ({ children, ...rest }) => {

	const user = useAppSelector(selectUser);

	return (
		<Route
			{...rest}
			render={({ location }) =>
				user ? (
					children
				) : (
					<Redirect
						to={{
							pathname: "/login",
							state: { from: location }
						}}
					/>
				)
			}
		/>
	);
}

export default App;
