import axios from "axios";
import React, { useState, useEffect, useRef } from "react";
import { AiFillCloseCircle, AiOutlineCloudUpload } from "react-icons/ai";
import { FaCompressArrowsAlt, FaExpandArrowsAlt } from "react-icons/fa";
import { BounceLoader } from "react-spinners";
import API from "../../utils/API";
import { backend } from "../../utils/env";
import Loading from "../Loading";

import './index.css';

export enum AllImagesState {
    hidden,
    peek,
    show
}

export interface ImageInfo {
    id: number;
    title: string;
    og_path: string;
    thumb_path: string;
    title_id: string;
}

interface AllImagesProps {
    actionMessage?: string | null;
    onClicky?: ((image: ImageInfo) => void) | null;
    filterOut?: Array<ImageInfo> | null;
    setStatus: (state: AllImagesState) => void;
    status: AllImagesState;
}

interface ImageType {
    title: string;
    file: File | null;
}

interface ImageUploadErrorTypes {
    title?: Array<string>;
    image?: Array<string>;
}

const AllImages = ({ actionMessage = null,
    onClicky = null,
    filterOut = null,
    setStatus, status }: AllImagesProps) => {

    const [upload, setUpload] = useState(false);
    const [loading, setLoading] = useState(false);
    const [uploading, setUploading] = useState(false);
    const [errors, setErrors] = useState<ImageUploadErrorTypes>({});
    const [image, setImage] = useState<ImageType>({
        title: '',
        file: null
    });
    const [images, setImages] = useState<Array<ImageInfo>>([]);
    const [imagesFromHost, setImagesFromHost] = useState<Array<ImageInfo>>([]);

    const imageRef = useRef<HTMLImageElement | null>(null);

    useEffect(() => {
        setLoading(true);
        const cancelTokenSource = axios.CancelToken.source();
        API.get(`/api/images/all`, {
            withCredentials: false,
            cancelToken: cancelTokenSource.token
        }).then((response) => {
            if (response.data.images)
                setImagesFromHost(response.data.images);
            setLoading(false);
        }).catch((error) => {

        })

        return () => cancelTokenSource.cancel();
    }, []);

    useEffect(() => {
        if (filterOut)
            setImages(imagesFromHost.filter((image: ImageInfo) => filterOut.find(i => i.id === image.id) === undefined));
        else
            setImages(imagesFromHost);
    }, [imagesFromHost, filterOut]);

    useEffect(() => {

        if (!image.file)
            return

        let reader = new FileReader();

        reader.onload = e => {
            let result = reader.result as string;

            if (!result)
                return

            imageRef && imageRef.current &&
                (imageRef.current.src = result);
        }

        reader.readAsDataURL(image.file);


    }, [image.file])


    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files !== null)
            setImage(i => ({
                ...i,
                title: i.title === '' && e.target.files ? e.target.files[0].name.split('.')[0] : i.title,
                file: e.target.files ? e.target.files[0] : i.file
            }))
        else
            setImage(i => ({
                ...i,
                file: null
            }))
    }


    const handleNewImage = () => {

        if (!image.file)
            return;

        let formData = new FormData();
        formData.append("image", image.file);
        formData.append("title", image.title);

        setUploading(true);
        API.post('/api/images/upload', formData).then(response => {
            if (response.data.image) {
                setUploading(false);
                setErrors({});
                setUpload(false)
                onClicky && onClicky(response.data.image);
            }
        }).catch(({ response }) => {
            setUploading(false);            
            if (response.data.errors)
                setErrors(response.data.errors)
        });
    }

    const closeUpload = () => {
        setImage({
            title: '',
            file: null
        })
        setUpload(false)
        setErrors({})
    }

    if (status === AllImagesState.hidden)
        return <></>;

    if (loading)
        return (
            <div className={`actions-on-portfolio  ${status === AllImagesState.show ? 'show' : 'peek'}`}>
                <Loading color="#232b2b" />                
            </div>
        )
    return (<>
        <div className={`actions-on-portfolio  ${status === AllImagesState.show ? 'show' : 'peek'}`}>
            <div className="actions-buttons">
                {status === AllImagesState.peek ?
                    <button onClick={e =>
                        setStatus(AllImagesState.show)}>
                        <FaExpandArrowsAlt />
                    </button>
                    :
                    <button onClick={e =>
                        setStatus(AllImagesState.peek)}>
                        <FaCompressArrowsAlt />
                    </button>
                }
                <button
                    onClick={e =>
                        setUpload(true)}>
                    <AiOutlineCloudUpload />
                </button>
                <button
                    onClick={e =>
                        upload ?
                            closeUpload()
                            : setStatus(AllImagesState.hidden)}>
                    <AiFillCloseCircle />
                </button>
            </div>

            <div className="all-images">
                {upload ?
                    <div className="new-image">
                        <label>Title</label>
                        <input type="text"
                            onChange={e => setImage(i => ({
                                ...i,
                                title: e.target.value
                            }))}
                            value={image.title}
                            name="title"
                            placeholder="Title" />
                        {errors && errors.title &&
                            <label className="error">{errors.title.join('&')}</label>
                        }

                        {uploading &&
                            <div className="image-loading">
                                <BounceLoader />
                            </div>
                        }

                        {image.file &&
                            <img ref={imageRef} alt="Chosen one" />
                        }
                        <input type="file"
                            onChange={handleFileChange}
                            name="file" />
                        <button onClick={handleNewImage}>
                            Submit
                            </button>
                    </div>
                    :
                    (images && images.map(image =>
                        <button key={image.id} onClick={e => onClicky && onClicky(image)}>
                            <img
                                alt={image.title}
                                src={`${backend}/${image.thumb_path}`} />
                            {actionMessage && <div className="action-message">
                                <p>{image.title}</p>
                                <p>{actionMessage}</p>
                            </div>}
                        </button>))}
            </div>
        </div>
    </>);
};

export default AllImages;
