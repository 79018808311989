import { motion } from "framer-motion";
import React, { useEffect, useState } from "react";
import { FaPlus } from "react-icons/fa";
import { useHistory, useLocation } from "react-router-dom";

import { useAppSelector } from "../../store/hooks";
import { selectUser } from "../../store/reducers/user";
import API from "../../utils/API";
import Loading from "../Loading";

import Package, { packageTemplate, PackageType } from '../Package';
import './index.css';

const Packages = () => {

	const [packages, setPackages] = useState<Array<PackageType>>([]);
	const [fetch, setFetch] = useState(true);
	const [loading, setLoading] = useState(false);

	const user = useAppSelector(selectUser);
	const location = useLocation();
	const history = useHistory();
	
	useEffect(() => {
		if (fetch) {
			setLoading(true);
			setFetch(false);
			API.get('/api/packages/all', {
				withCredentials:false
			}).then(response => {
				if (response.data.packages)
					setPackages(response.data.packages)
				setLoading(false);
			}).catch(error => {

			})
		}
	}, [fetch])

	if (loading)
		return <Loading />

	return (
		<motion.div 
			layout='position'
			className="packages">
			{ packages.map(pack =>
				<Package
					pack={pack}
					setPackages={setPackages}
					key={pack.id} />)}
			{ user && location.pathname === '/packages/all' &&
				<div className="new-package">					
					<button className="create-package"
							onClick={e => {
								setPackages(p => [...p, packageTemplate]) 
								history.push('/packages/new')
							}}>
						<FaPlus />
						Create Package
					</button>
				</div>
			}
		</motion.div>
	);
};

export default Packages;
