import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import AvatarEditor from "react-avatar-editor";
import Loader from "react-spinners/BeatLoader";
import API from "../../utils/API";
import { backend } from "../../utils/env";
import { ImageInfo } from "../AllImages";
import Loading from "../Loading";

import './index.css';

export interface AvatarInfo {
    zoom: number;
    rotate: number;
    x: number;
    y: number;
}

export const avatarTemplate: AvatarInfo = {
    zoom: 1,
    rotate: 0,
    x: 1,
    y: 1,
}

interface FetchImageProps {
    id: number;
    thumb?: boolean;
    width?: number;
    height?: number;
    asAvatar?: boolean;
    avatar?: AvatarInfo | null;
    onLoad?: (() => void) | null;
    onError?: (() => void) | null;
    setAvatar?: ((setter: (current: AvatarInfo) => AvatarInfo) => void) | null;
    edit?: boolean
}

const FetchImage = ({
    id,
    thumb = false,
    asAvatar = false,
    avatar = null,
    setAvatar = null,
    onLoad = null,
    onError = null,
    edit = true,
    width = 250,
    height = 250 }: FetchImageProps) => {

    const [image, setImage] = useState<ImageInfo | null | 'error'>(null);
    const [loading, setLoading] = useState(true);

    const imageRef = useRef<HTMLImageElement | null>(null);

    useEffect(() => {

        const cancelTokenSource = axios.CancelToken.source();

        setLoading(true);
        API.get(`/api/images/byID/${id}`, {
            cancelToken: cancelTokenSource.token,
            withCredentials:false
        }).then(response => {
            if (response.data.image)
                setImage(response.data.image)
        }).catch(response => {
            onError && onError();
            setImage('error');
        })

        return () => cancelTokenSource.cancel();
    }, [id, onError])

    useEffect(() => {

        const loadedImage = () => {
            setLoading(false)
            onLoad && onLoad();
        }

        const imageError = () => {
            setLoading(false)
            onError && onError();
        }

        if (image && imageRef.current) {
            imageRef.current.addEventListener('load', loadedImage);
            imageRef.current.addEventListener('error', imageError);
        }

    }, [image, onLoad, imageRef, onError]);



    if (!image)
        return <div className="fetch-image loading">
            <Loader color='#232b2b' />
        </div>

    if (image === 'error')
        return <></>;

    return (
            <>
            { loading && <Loading color="#232b2b"/>}
            {  asAvatar && avatar && setAvatar ?
                <AvatarEditor
                    image={`${backend}/${thumb ? image.thumb_path : image.og_path}`}
                    width={width}
                    height={height}
                    style={{
                        cursor: edit ? 'grab' : 'default'
                    }}
                    border={0}
                    color={[255, 255, 255, 0.6]} // RGBA
                    scale={avatar.zoom}
                    rotate={avatar.rotate}
                    position={{ x: avatar.x, y: avatar.y }}
                    onLoadSuccess={() => {
                        setLoading(false)
                        onLoad && onLoad()
                    }}
                    onLoadFailure={() => {
                        setLoading(false)
                        onError && onError()
                    }}
                    onPositionChange={({ x, y }) => {
                        edit &&
                            setAvatar(avat => ({
                                ...avat,
                                x: x,
                                y: y
                            }))
                    }} /> :
                <img
                    ref={imageRef}
                    src={`${backend}/${thumb ? image.thumb_path : image.og_path}`}
                    alt={image.title} />
            }
            </>)
};

export default FetchImage;
