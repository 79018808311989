import React, { useState } from "react";
import { PuffLoader } from "react-spinners";
import API from "../../utils/API";
import { backend } from "../../utils/env";

import './index.css';

type ImageData = {
    id: number,
    title: string,
    og_path: string,
    thumb_path: string
}

type ImageViewerProps = {
    image: ImageData;
    deleteHanlde: () => void;
}

interface ImageEditErrors {
    title?: Array<string>
}

const ImageViewer = ({ image, deleteHanlde }: ImageViewerProps) => {

    const [deleted, setDeleted] = useState(false);
    const [deleteConfirm, setDeleteConfirm] = useState(false);
    const [edit, setEdit] = useState(false);
    const [title, setTitle] = useState(image.title);
    const [ogTitle, setOgTitle] = useState(image.title);
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState<ImageEditErrors>({})

    const handleDelete = (e: React.FormEvent<HTMLButtonElement>) => {
        setLoading(true);
        API.get(`/api/images/delete/${image.id}`).then(response => {
            console.log(response);
            setDeleted(true);
            setLoading(false);
            deleteHanlde();
        }).catch(response => {
            console.log(response);
        })
    }

    const handleEdit = (e: React.FormEvent<HTMLButtonElement>) => {
        setEdit(true);
    }

    const handleCancelEdit = (e: React.FormEvent<HTMLButtonElement>) => {
        setEdit(false);
        setTitle(ogTitle)
        setErrors({});
    }

    const handleTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target && e.target.value !== null)
            setTitle(e.target.value);
    }

    const handleEditSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setLoading(true);
        API.post(`/api/images/edit/${image.id}`, {
            title: title
        }).then(response => {
            console.log(response);
            setEdit(false);
            setOgTitle(title);
            setLoading(false);
            setErrors({});
        }).catch(response => {
            setLoading(false);
            if (response.response.data.errors)
                setErrors(response.response.data.errors)
        })
    }

    const handleDeleteConfirm = (e: React.FormEvent<HTMLButtonElement>) => {
        setDeleteConfirm(true);
    }

    const handleNevermind = (e: React.FormEvent<HTMLButtonElement>) => {
        setDeleteConfirm(false);        
    }

    if (deleted)
        return <></>;

    return (
        <div className="image-viewer">
            {loading ?
                <div className="loading">
                    <PuffLoader />
                </div>
                : deleteConfirm ?
                    <div className="actions">
                        <button type="button" onClick={handleDelete}>
                            Yes, Delete
            </button>
                        <button type="button" onClick={handleNevermind}>
                            No, nevermind
            </button>
                    </div> :
                    !edit &&
                    <div className="actions">
                        <button type="button" onClick={handleDeleteConfirm}>
                            Delete
                </button>
                        <button type="button" onClick={handleEdit}>
                            Edit
                </button>
                    </div>}
            { edit ?
                <form onSubmit={handleEditSubmit}>
                    <input type="text"
                        name="title"
                        onChange={handleTitleChange}
                        value={title} />
                    {errors && errors.title &&
                        <label className="error">
                            {errors.title.join(' & ')}
                        </label>
                    }
                    {!loading && <div className="actions">
                        <button type="submit" >Save</button>
                        <button type="button"
                            onClick={handleCancelEdit} >
                            Cancel
                        </button>
                    </div>}
                </form>
                :
                <h4>{title}</h4>
            }
            <img src={`${backend}/${image.thumb_path}`}
                alt={title} />
        </div>
    );
};

export default ImageViewer;
