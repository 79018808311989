import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import API from '../../utils/API';
import type { RootState } from "../index";

interface UserState {
    name: string | null;
    username: string | null;
};

const initialState: UserState = {
    name: null,
    username: null,
};

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        login: (state, action: PayloadAction<UserState>) => ({
            ...state,
            name: action.payload.name,
            username: action.payload.username
        }),
        logout: state => ({
            ...state,
            name: null,
            username: null
        }),
    }
});

export const getUser = () => async (dispatch: (arg0: { type: string; payload?: UserState; }) => void) => {
    try{
        const res = await API.get(`/api/user`)
        dispatch( {
            type: 'user/login',
            payload: {
                name: res.data.name,
                username: res.data.username
            }
        })
    }
    catch(e){
        dispatch( {
            type: 'user/logout'
        })
    }
}

export const {
    login,
    logout
} = userSlice.actions;

export const selectUser = (state: RootState) => state.user.name === null ? null : state.user;

export default userSlice.reducer;
