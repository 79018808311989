import React, { useEffect, useState } from "react";
import { BiAddToQueue } from "react-icons/bi";
import { Link, useHistory } from "react-router-dom";

import API from "../../utils/API";
import ImageViewer from "../ImageViewer";
import Loading from "../Loading";

import './index.css';

type ImageData = {
    id: number,
    title: string,
    og_path: string,
    thumb_path: string
}

const ImageAdmin = () => {

    const [images, setImages] = useState<Array<ImageData>>([]);
    const [fetch, setFetch] = useState(true);
    const [loading, setLoading] = useState(true);

    const history = useHistory();
    
    useEffect(() => {
        if (fetch) {
            setLoading(true);
            API.get('/api/images/all', {
				withCredentials:false
			}).then(response => {
                setImages(response.data?.images);
                setFetch(false);
                setLoading(false);
            }).catch(response => {
                setFetch(false);
                setLoading(false);
                setImages([
                    {
                        "id": 26,
                        "title": "make it rainssss",
                        "og_path": "images/make-it-rainssss.jpg",
                        "thumb_path": "images/make-it-rainssss_thumb.jpg"
                    }
                ]);
            })
        }
    }, [fetch])

    const handleDeleted = () => {
        setFetch(true);
    }

    if (loading)
        return <Loading />

    return (
        <div className="image-admin">
            { images.length > 0 &&
                <>
                    <div className="edit-actions sticky">
                        <button
                            onClick={e => history.push('/images/upload')}
                            className={`edit-package`}>
                            <BiAddToQueue />
                        </button>
                    </div>
                    <div className="image-header">
                        <p>{images.length} Image{images.length > 1 && 's'}</p>
                    </div>
                </>}
            { images.map(image =>
                <ImageViewer image={image}
                    key={image.id}
                    deleteHanlde={handleDeleted} />
            )}
            { images.length === 0 &&
                <div className="empty">
                    <p>You have no images</p>
                    <Link to='/images/upload'>Add Images</Link>
                </div>}
        </div>
    );
};

export default ImageAdmin;
