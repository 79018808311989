import React, { useState } from "react";
import { BiMailSend } from "react-icons/bi";
import API from "../../utils/API";
import Loading from "../Loading";

import './index.css';

interface FormData {
    name: string
    email: string
    message: string
}

const formDataTemplate: FormData = {
    name: '',
    email: '',
    message: ''
}

interface Errors {
    name?: Array<string>,
    email?: Array<string>,
    message?: Array<string>,
}

const Contact = () => {

    const [formData, setFormData] = useState(formDataTemplate);
    const [errors, setErrors] = useState<Errors>({});
    const [sending, setSending] = useState(false);

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setSending(true);
        API.post('/api/send_message', formData, {
            withCredentials:false
        }).then(response => {
            setSending(false);
            setFormData(formDataTemplate);

            //Plate
        }).catch(error => {
            if (error.response.data.errors)
                setErrors(error.response.data.errors);
        })
    }

    const handleChange = (e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>) => {
        setFormData(formData => ({
            ...formData,
            [e.target.name]: e.target.value
        }))
    }

    return (
        <div className="contact">
            { sending &&
				<Loading
					overlay
					label={"Sending"}
					icon={<BiMailSend/>} />}
            <h1>Get in touch with me</h1>
            <form onSubmit={handleSubmit}>
                <div className="labels">
                    <label htmlFor="name">Name</label>
                    {errors && errors.name && <label className="error">
                        {errors.name.join(' & ')}
                    </label>}
                </div>
                <input name="name"
                    value={formData.name}
                    type="text"
                    placeholder="Your name"
                    onChange={handleChange} />
                <div className="labels">
                    <label htmlFor="email">Email</label>
                    {errors && errors.email && <label className="error">
                        {errors.email.join(' & ')}
                    </label>}
                </div>
                <input name="email"
                    type="email"
                    value={formData.email}
                    placeholder="Your email"
                    onChange={handleChange} />
                <div className="labels">
                    <label htmlFor="message">Message</label>
                    {errors && errors.message && <label className="error">
                        {errors.message.join(' & ')}
                    </label>}
                </div>
                <textarea name="message"
                    value={formData.message}
                    placeholder="Your message"
                    onChange={handleChange} />
                <button type="submit">Send</button>
            </form>
        </div>
    );
};

export default Contact;
