import { motion } from "framer-motion";
import React, { useState } from "react";
import { useEffect } from "react";
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import { AiFillDelete } from "react-icons/ai";
import 'react-circular-progressbar/dist/styles.css';
import './index.css';
import API from "../../utils/API";
import { IoMdCheckmark, IoMdCloudUpload } from "react-icons/io";
import { PuffLoader } from "react-spinners";
import { ImageForm } from "../ImageUpload";

interface ImageEditorDefaultProps {
    file: File;
    setImages: ( setter: ( current: Array<ImageForm> ) => Array<ImageForm> ) => void
}

interface ImageEditorProps extends ImageEditorDefaultProps {
    forwardedRef: React.ForwardedRef<HTMLButtonElement>;    
}

interface ImageEditErrors {
    title?: Array<string>;
}

const ImageEditor = ({ 
    file,
    forwardedRef,
    setImages,
 }: ImageEditorProps) => {

    const [title, setTitle] = useState(file.name.replace(/\.[^/.]+$/, ""));
    const [imageUrl, setImageUrl] = useState("");
    const [confirmDelete, setConfirmDelete] = useState(false);
    const [progress, setProgress] = useState(0);
    const [uploading, setUploading] = useState(false);
    const [waiting, setWaiting] = useState(true);
    const [errors, setErrors] = useState<ImageEditErrors>({});
    const [uploaded, setUploaded] = useState(false);
    const [done, setDone] = useState(false);

    useEffect(() => {
        let reader = new FileReader();
        reader.onload = function (e) {
            if (e.target && typeof (e.target.result) === "string")
                setImageUrl(e.target.result);
        }
        reader.readAsDataURL(file);
        setConfirmDelete(false);
        setUploading(false);
        setProgress(0);
        setWaiting(true);
        setErrors({});
        setUploaded(false);
    }, [file])

    useEffect(() => {
        if (uploaded && !done) {
            const timeout = setTimeout(() => {
                setDone(true);
            }, 500);

            return () => {
                clearTimeout(timeout)
            }
        }
        return () => {}
    }, [uploaded, done]);

    useEffect(() => {
        if (progress > 0 && progress < 100 && uploading && waiting)
            setWaiting(false);

        if (progress === 100 && !waiting)
            setTimeout(function () {
                setWaiting(true);
            }, 500);


    }, [progress, uploading, setWaiting, waiting]);

    const handleTitle = (e: React.ChangeEvent<HTMLInputElement>) => {
        setTitle(e.target.value);
    }

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        const config = {
            onUploadProgress: function (progressEvent: { loaded: number; total: number; }) {
                var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                setProgress(percentCompleted);
            }
        }

        e.preventDefault();
        let formData = new FormData();
        formData.append("image", file);
        formData.append("title", title);
        setUploading(true);
        API.post('/api/images/upload', formData, config).then(response => {
            setUploaded(true);
        }).catch(response => {
            if (response.response.data.errors) {
                setErrors(response.response.data.errors)
            }
            setUploading(false);
        });
    }

    const handleDeleteForReal = (e: React.FormEvent<HTMLButtonElement>) => {
        setDone(true);
        setImages(images => images.filter(image => image.file.name !== file.name))
    }

    const handleDelete = (e: React.FormEvent<HTMLButtonElement>) => {
        setConfirmDelete(true);
    }

    const handleCancelDelete = (e: React.FormEvent<HTMLButtonElement>) => {
        setConfirmDelete(false);
    }

    const cdVariants = {
        open: { opacity: 1, x: 0, y: 0, scale: 1 },
        closed: { opacity: 0, x: "0", y: "0", scale: 0.1 },
    }

    const progress_style = buildStyles({
        rotation: 0,
        strokeLinecap: 'butt',
        pathTransitionDuration: 0.5,
        pathColor: `rgb(47, 51, 83)`,
        textColor: 'rgb(47, 51, 83)',
        textSize: 12
    });

    if (done)
        return <></>;

    return (
        <div className={`image-editor ${confirmDelete || uploading ? 'confrim' : 'regular'}`}>
            { uploading &&
                <div className="progress-circle-wrapper">
                    {uploaded ?
                        <div className="donezo">
                            <IoMdCheckmark />
                            <p>Done!</p>
                        </div>
                        : waiting ?
                            <PuffLoader size={140}
                                color="#2f3353" />
                            :
                            <CircularProgressbar value={progress}
                                styles={progress_style}
                                text={'uploading'}
                                strokeWidth={3}
                                className="progress-circle" />
                    }
                </div>
            }
            <motion.div
                variants={cdVariants}
                initial="closed"
                transition={{ duration: 0.15, type: "tween" }}
                animate={confirmDelete ? "open" : "closed"}
                className="confirm-delete">
                <p>Remove this image?</p>
                <div className="options">
                    <button onClick={handleDeleteForReal}>Yes</button>
                    <button onClick={handleCancelDelete}>No</button>
                </div>
            </motion.div>
            <form onSubmit={handleSubmit}>
                <div className="image">
                    <div className="title">
                        <label htmlFor="title">
                            Title:
                        </label>
                        <input type="text"
                            placeholder="Title"
                            value={title}
                            onChange={handleTitle}
                            name="title" />
                    </div>
                    {errors.title &&
                        <label className="error-label">
                            {errors.title}
                        </label>
                    }
                    <div className="image-holder">
                        <div className="actions">
                            <button type="submit" className="submit action" ref={forwardedRef}>
                                <IoMdCloudUpload />
                            </button>
                            <button onClick={handleDelete}
                                type="button"
                                className="delete action">
                                <AiFillDelete />
                            </button>
                        </div>
                        <img src={imageUrl} alt="Preview" />
                    </div>
                    <p>{file.name}</p>
                </div>
            </form>
        </div>
    );
};

export default React.forwardRef<HTMLButtonElement, ImageEditorDefaultProps>((props, ref) =>
    <ImageEditor {...props}
        forwardedRef={ref} />);
