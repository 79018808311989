import React, { useState, useEffect } from "react";

import './index.css';
import 'react-inner-image-zoom/lib/InnerImageZoom/styles.min.css';
import {
	Link,
	useParams,
	useHistory,
	useLocation
} from "react-router-dom";

import {
	IoMdArrowDropleftCircle, IoMdArrowDroprightCircle,
	// IoMdArrowDroprightCircle
} from "react-icons/io";

import { GrClose } from "react-icons/gr";
import InnerImageZoom from 'react-inner-image-zoom';
import { motion, AnimatePresence } from "framer-motion";
import API from "../../utils/API";
import ImageMe from "../Image";

import { useAppSelector } from '../../store/hooks'
import { selectUser } from '../../store/reducers/user'
import AllImages, { ImageInfo } from "../AllImages";
import { FaPencilAlt, FaSave } from "react-icons/fa";
import { IoCloseCircleOutline } from "react-icons/io5";
import Loading from "../Loading";
import { backend } from "../../utils/env";
import { BiAddToQueue, BiArrowBack } from "react-icons/bi";
import { AiOutlineRight } from "react-icons/ai";

type PortfolioEntrie = {
	id: number,
	title: string,
	title_id: string,
	status: string,
	images: Array<ImageInfo>
};


type PortfolioEntriesType = Array<PortfolioEntrie>;

type PortfolioSlideParams = {
	entryId: string,
	imageId: string
};

const variants = {
	enter: (direction: number) => {
		return {
			x: direction > 0 ? 1000 : -1000,
			opacity: 0
		};
	},
	center: {
		zIndex: 1,
		x: 0,
		opacity: 1
	},
	exit: (direction: number) => {
		return {
			zIndex: 0,
			x: direction < 0 ? 1000 : -1000,
			opacity: 0
		};
	}
};

const swipeConfidenceThreshold = 10000;
const swipePower = (offset: number, velocity: number) => {
	return Math.abs(offset) * velocity;
};

export const PortfolioSlide = () => {

	const { entryId, imageId } = useParams<PortfolioSlideParams>();
	const [fetch, setFetch] = useState(true);
	const [loading, setLoading] = useState(true);
	const [entry, setEntry] = useState<PortfolioEntrie | null>(null);
	const [error, setError] = useState(false);
	const [errorMessage, setErrorMessage] = useState("");
	const [images, setImages] = useState<Array<ImageInfo>>([])
	const [[currentImage, direction], setPage] = useState([0, 0]);

	const history = useHistory();

	useEffect(() => {
		const finishFetch = () => {
			setLoading(false);
		}

		if (fetch) {
			setLoading(true);
			setFetch(false);
			API.get('/api/portfolios/full/byTitle/' + entryId, {
				withCredentials: false
			}).then((response) => {

				if (!response || !response.data)
					return;

				if (response.data.portfolio)
					setEntry(response.data.portfolio)

				if (response.data.images) {
					setImages(response.data.portfolio.images
						.map((image: number) =>
							response.data.images.find((im: ImageInfo) =>
								im.id === image))
						.filter((image: ImageInfo | undefined) => image !== undefined))
				}
				finishFetch();
			}).catch(response => {
				setError(true);
				finishFetch();
				setErrorMessage(response.response.data.message)
			})
		}
	}, [fetch, entryId])

	useEffect(() => {
		console.log(images)
		if (images.length > 0)
			setPage([
				images.findIndex(image => image && image.title_id ?
					image.title_id === imageId : false),
				0]);
	}, [images, imageId])

	useEffect(() => {
		if (currentImage !== 0 && images.length > 1) {
			let prevImage = new Image()
			prevImage.src = `${backend}/${images[currentImage - 1].og_path}`
		}
		if (currentImage < images.length - 1) {
			let nextImage = new Image()
			nextImage.src = `${backend}/${images[currentImage + 1].og_path}`
		}
	}, [currentImage, images])

	const paginate = (newDirection: number) => {
		setPage([currentImage + newDirection, newDirection]);
		history.push(`/portfolio/${entryId}/${images[currentImage + newDirection].title_id}`)
	};


	if (error)
		return <div className="portfolio">
			<div className="error">
				<h4>Error</h4>
				<p>{errorMessage}</p>
			</div>
		</div>


	if (loading || currentImage < 0)
		return <Loading />


	return (
		<div className="portfolio-slide" >

			<div className="control">
				{(currentImage !== 0 && images.length > 1) &&
					// <Link to={`/portfolio/${entryId}/${images[currentImage - 1].title_id}`}>
					// 	<IoMdArrowDropleftCircle />
					// </Link>
					<button onClick={e => {
						paginate(-1);
					}}>
						<IoMdArrowDropleftCircle />
					</button>
				}
			</div>

			<AnimatePresence initial={false} custom={direction}>
				<motion.div
					className="image-holder"
					key={currentImage}
					custom={direction}
					variants={variants}
					initial="enter"
					animate="center"
					exit="exit"
					transition={{
						x: { type: "spring", stiffness: 300, damping: 30 },
						opacity: { duration: 0.2 }
					}}
					drag="x"
					dragConstraints={{ left: 0, right: 0 }}
					dragElastic={1}
					onDragEnd={(e, { offset, velocity }) => {
						const swipe = swipePower(offset.x, velocity.x);

						if (swipe < -swipeConfidenceThreshold) {
							paginate(1);
						} else if (swipe > swipeConfidenceThreshold) {
							paginate(-1);
						}
					}}>
					<div className="title">
						<Link to={`/portfolio/${entryId}`} >
							<GrClose />
						</Link>
						<span>{entry?.title} <AiOutlineRight />  {images[currentImage].title} </span>
					</div>

					<InnerImageZoom
						className="image-slide"
						src={`${backend}/${images[currentImage].og_path}`}
						zoomScale={1}
						alt={images[currentImage].title} />
				</motion.div>
			</AnimatePresence>


			<div className="control">
				{
					currentImage < images.length - 1 &&
					// <Link to={`/portfolio/${entryId}/${images[currentImage + 1].title_id}`}>
					// 	<IoMdArrowDroprightCircle />
					// </Link>
					<button onClick={e => {
						paginate(1);
					}}>
						<IoMdArrowDroprightCircle />
					</button>
				}
			</div>

		</div >
	);
};

interface PortfolioEntryProps {
	entry: PortfolioEntrie | 'new';
	setEntries?: ((next: (current: PortfolioEntriesType) => PortfolioEntriesType) => void) | null;
};

enum AllImagesState {
	hidden,
	peek,
	show
}

interface PortfolioEntryNewError {
	title?: Array<string>
}

const PortfolioEntry = ({ entry, setEntries = null }: PortfolioEntryProps) => {

	const [state, setState] = useState<string>('entry');
	const [uploading, setUploading] = useState(false);
	const location = useLocation();
	const history = useHistory();
	const [errors, setErrors] = useState<PortfolioEntryNewError>({});
	const [saving, setSaving] = useState(false);
	const [editTitle, setEditTitle] = useState(false);
	const user = useAppSelector(selectUser);
	const [newTitle, setNewTitle] = useState('');
	const [newPotfolio, setNewPotfolio] = useState(false);
	const [showImages, setShowImages] = useState<AllImagesState>(AllImagesState.hidden);
	const [showActions, setShowActions] = useState(false);
	const [deleteConfirm, setDeleteConfirm] = useState(false);
	const [ogTitle, setOgTitle] = useState(entry === 'new' ? '' : entry.title);

	useEffect(() => {
		if (entry === 'new') {
			if (location.pathname === '/portfolio/all')
				setState('entry')
			else
				setState('hidden')
		} else if (location.pathname === `/portfolio/${entry.title_id}`) {
			setState('portfolio-single');
		} else if (location.pathname === '/portfolio/all') {
			setState('entry');
		} else {
			setState('hidden');
		}
	}, [location.pathname, entry]);

	const removeImage = (id: number) => {
		if (entry === 'new')
			return;
		setSaving(true)
		API.post(`/api/portfolios/remove_image/${entry?.id}`, {
			image: id
		}).then(response => {
			if (response.data.success && setEntries)
				setEntries(entries => (
					entries.map(entrie => entrie.id === entry.id ? {
						...entrie,
						images: entrie.images.filter(image => image.id !== id)
					} : entrie)
				))
			setSaving(false);
		}).catch(response => {

		})
	}

	const uploadPotfolio = () => {

		setUploading(true);
		API.post(`/api/portfolios/upload`, {
			title: newTitle
		}).then((response => {
			if (response.data.portfolio && setEntries) {
				setEntries(entries => [...entries, response.data.portfolio])
				setNewTitle('');
				setNewPotfolio(false);
			}
			setUploading(false);
		})).catch(error => {
			if (error.response.data.errors)
				setErrors(error.response.data.errors)
			setUploading(false);
		})
	}

	const removeThisPortfolio = () => {

		if (entry === 'new')
			return;
		setSaving(true)
		API.get(`/api/portfolios/delete/${entry.id}`).then(response => {
			setEntries && setEntries(entrie => entrie.filter(e => e.id !== entry.id))
			setSaving(false)
			history.replace('/portfolio/all')
		}).catch(error => {

		})
	}

	const addImageToEntry = (image: ImageInfo) => {
		if (entry === 'new')
			return;
		setSaving(true)
		API.post(`/api/portfolios/add_image/${entry?.id}`, {
			image: image.id
		}).then(response => {
			if (response.data.image) {
				setEntries && setEntries(entries =>
					entries.map(entrie => entry.id === entrie.id ?
						{
							...entrie,
							images: entrie.images ? [...entrie.images, response.data.image] : [response.data.image]
						} : entrie)
				);
			}
			setSaving(false);
		}).catch(response => {

		})
	}

	const toggleStatus = () => {

		if (entry === 'new')
			return;

		setSaving(true);
		API.post(`/api/portfolios/status/${entry.id}`).then(response => {
			if (response.data.success)
				setEntries && setEntries(entries => entries.map(entrie => entrie.id === entry.id ? {
					...entrie,
					status: entrie.status === 'public' ? 'private' : 'public'
				} : entrie))
			setSaving(false)
		}).catch(error => {

		})
	}

	const handleTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		if (entry === 'new')
			return;

		setEntries && setEntries(entries => entries.map(en => en.id === entry.id ? ({
			...en,
			title: e.target.value
		}) : en))
	}

	const saveTitle = () => {
		if (entry === 'new')
			return;

		API.post(`/api/portfolios/edit/${entry.id}`, {
			title: entry.title
		}).then(response => {
			if (response.data.portfolio) {
				setOgTitle(response.data.portfolio.title);
				setEditTitle(false);
				setEntries && setEntries(entries => entries.map(en => en.id === entry.id ? response.data.portfolio : en))
				history.replace(`/portfolio/${response.data.portfolio.title_id}`)
			}
		}).catch(error => {

		})
	}

	if (entry === 'new')
		return (
			<>
				{ newPotfolio ?
					<motion.div
						layout
						className={state + ' new-entry on'}>
						{uploading && <Loading color="#232b2b" />}
						{newPotfolio ? <>
							<label htmlFor='title'>Title</label>
							{errors.title &&
								<label className="error">
									{errors.title.join(' & ')}
								</label>
							}
							<input type="text" name="title"
								value={newTitle}
								onChange={e => setNewTitle(e.target.value)}
								placeholder="New Title" />
							<div>
								<button onClick={e => uploadPotfolio()} >
									Create
								</button>
								<button onClick={e => {
									setNewPotfolio(false)
									setErrors({})
									setNewTitle('')
								}}>
									Cancel
								</button>
							</div>
						</> :
							<button onClick={e => setNewPotfolio(true)}>New Portfolio</button>
						}
					</motion.div>
					: state !== 'hidden' &&
					<div className="edit-actions sticky">
						<button
							onClick={e => setNewPotfolio(true)}
							className={`edit-package`}>
							<BiAddToQueue />
						</button>
					</div>
				}
			</>
		)


	if ((!entry.images || entry.images.length === 0 || entry.status === 'private') && !user)
		return <></>;

	return (
		<>
			{ state === 'portfolio-single' &&
				<div className="portfolio-title">
					<button onClick={e => history.goBack()}
						className="no-style">
						<BiArrowBack />
					</button>
					{editTitle ? <>
						<input type="text"
							onChange={handleTitleChange}
							value={entry.title} />
						<button onClick={e => saveTitle()}>
							<FaSave />
						</button>
						<button onClick={e => {
							setEditTitle(false);
							setEntries && setEntries(entries => entries.map(en => en.id === entry.id ? ({
								...en,
								title: ogTitle
							}) : en))
						}}>
							Cancel
						</button>
					</> :
						<h4>{entry.title}</h4>
					}
					{user &&
						<p>
							{
								entry.status === 'private' ?
									'Private portfolio' :
									'Public portfolio'
							}
						</p>
					}
				</div>
			}
			{ showImages !== AllImagesState.show &&
				<>
					<motion.div
						layout='position'
						className={state + ((!entry.images || entry.images.length === 0) ? ' empty' : '')}
						onClick={(e) => {
							if (state === 'entry')
								history.push(`/portfolio/${entry.title_id}`)
						}}>
						{saving &&
							<Loading
								overlay
								label={"Saving"}
								icon={<FaSave />} />
						}
						{entry.images && entry.images.map((image, i) => <motion.div layout='position' className="image-wrapper" >
							<ImageMe key={image.id}
								image={image}
								entryId={entry.title_id}
								removeEvent={removeImage}
								edit={(user !== null && state === 'portfolio-single')} /></motion.div>
						)}
						<div className="info">
							<div className="info-content">
								<h2>{entry.title}</h2>
								<p>{(entry.images && entry.images.length > 0) ?
									entry.images.length + ' image' + (entry.images.length > 1 ? 's' : '') : 'Empty portfolio'}</p>
							</div>
						</div>
					</motion.div>
					{ user !== null && state === 'portfolio-single' && <>
						{ !showActions ?
							<div className="edit-actions sticky">
								<button
									onClick={e => setShowActions(true)}
									className={`edit-package`}>
									<FaPencilAlt />
								</button>
							</div>
							:
							<div className="actions-floater">
								{showImages === AllImagesState.hidden &&
									<button className="action-floater"
										onClick={e =>
											setShowImages(AllImagesState.peek)}>
										Add Images
									</button>
								}
								<button className="action-floater"
									onClick={e =>
										toggleStatus()}>
									{entry.status === 'public' ? 'Make Private' : 'Make Public'}
								</button>
								<button className="action-floater"
									onClick={e => setEditTitle(true)} >
									Edit Title
								</button>
								<div className="deleter">
									{!deleteConfirm ?
										<button className="action-floater"
											onClick={e =>
												setDeleteConfirm(true)}>
											Delete Portfolio
										</button> :
										<>
											<button className="action-floater"
												onClick={e =>
													setDeleteConfirm(false)}>
												Nevermind
											</button>
											<button className="action-floater"
												onClick={e =>
													removeThisPortfolio()}>
												Yes Please
											</button>
										</>
									}
								</div>
								<button className="action-floater"
									onClick={e =>
										setShowActions(false)}>
									<IoCloseCircleOutline />
								</button>
							</div>
						}
					</>
					}
				</>
			}
			{ user !== null && state === 'portfolio-single' && <>

				{showImages !== AllImagesState.hidden &&
					<AllImages onClicky={addImageToEntry}
						filterOut={entry.images}
						status={showImages}
						setStatus={setShowImages}
						actionMessage="Add to portfolio" />}
			</>}
		</>
	);
};

const Portfolio = () => {

	const user = useAppSelector(selectUser);

	const [entries, setEntries] = useState<PortfolioEntriesType>([])
	const [loading, setLoading] = useState(true);
	const [fetch, setFetch] = useState(true);
	const [error, setError] = useState(false);
	const [errorMessage, setErrorMessage] = useState("");


	useEffect(() => {
		if (fetch) {
			setLoading(true);
			setFetch(false);
			API.get('/api/portfolios/all', {
				withCredentials: false
			}).then((response) => {
				if (response.data.error === true) {
					console.log(response.data)
					setError(true);
					if (response.data.message)
						setErrorMessage(response.data.message);
					setLoading(false);
					setFetch(false);
					return;
				}

				setEntries(response.data.portfolios)
				setLoading(false);
				setError(false);
			}).catch((error) => {
				setLoading(false);
				setError(true);
				setErrorMessage("Something went wrong! Check console for deets");
				console.log(error.data);
			})
		}
	}, [fetch])


	if (error)
		return <div className="portfolio">
			<div className="error">
				<h4>Error</h4>
				<p>{errorMessage}</p>
			</div>
		</div>


	if (loading)
		return <Loading />

	return (
		<div className="portfolio">
			{ entries && entries.map(entrie =>
				<PortfolioEntry
					key={entrie.id}
					setEntries={setEntries}
					entry={entrie} />)}

			{ user !== null && <>
				<PortfolioEntry entry='new'
					setEntries={setEntries} />
			</>}
		</div>
	);
};

export default Portfolio;