import React from "react";

import Experience, { ExperienceType } from '../Experience';

import './index.css';

const slides1 = [
    {
	src:'https://upload.wikimedia.org/wikipedia/commons/9/9b/Lake_Agnes%2C_Banff_NP_Alberta_Canada.jpg',
	video:false
    },{
	src:"https://d33wubrfki0l68.cloudfront.net/dd23708ebc4053551bb33e18b7174e73b6e1710b/dea24/static/images/wallpapers/shared-colors@2x.png",
	video:false
    }
];


const slides2 = [
    {
	src:"https://d33wubrfki0l68.cloudfront.net/594de66469079c21fc54c14db0591305a1198dd6/3f4b1/static/images/wallpapers/bridge-01@2x.png",
	video:false
    },
    {
	src:'http://localhost:4000/video',
	video:true
    }
];

const Experiences = () => {
    return (
        <div className="experiences">
          <div className="content">
              <Experience slides={slides2}
			  title={'The Falkin Trails'}
			  kind={ExperienceType.Hiking}
			  description={'There once was a man named after another man whose name was that of the initial father\'s grandad name'}/>
              <Experience slides={slides1}
			  kind={ExperienceType.Biking}	     
			  title={'The strong trail'}
			  description={'A simple strong induced former treceptor through the counter sling'}/>
          </div>
        </div>
    );  
};

export default Experiences;
