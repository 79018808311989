import React, { useEffect, useState } from "react";
import { FaSave } from "react-icons/fa";
import { useAppDispatch } from "../../store/hooks";
import { logout } from "../../store/reducers/user";
import API from "../../utils/API";
import Loading from "../Loading";

import './index.css';


interface PasswordInfoType {
    current_password: string
    new_password: string
    confirm_new_password: string
}

interface PasswordInfoErrors {
    current_password?: Array<string>
    new_password?: Array<string>
    confirm_new_password?: Array<string>
}

const passwordInfoTemplate: PasswordInfoType = {
    current_password: '',
    new_password: '',
    confirm_new_password: '',
}

interface component {
    public: boolean;
}

const componentTemplate: component = {
    public: true
}

const UserSettings = () => {

    const [changePassword, setChangePassword] = useState(false)
    const [passwordInfo, setPasswordInfo] = useState<PasswordInfoType>(passwordInfoTemplate)
    const [errors, setErrors] = useState<PasswordInfoErrors>({})
    const [passwordChanged, setPasswordChanged] = useState(false);
    const [saving, setSaving] = useState(false);
    const [fetch, setFetch] = useState(true);

    const [loading, setLoading] = useState(true);
    const [component, setComponent] = useState<component>(componentTemplate)

    const dispatch = useAppDispatch();

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setPasswordInfo(pi => ({
            ...pi,
            [e.target.name]: e.target.value
        }))
    }

    const sendChangePassword = () => {
        API.post(`/api/user/change_password`, passwordInfo).then(response => {
            setChangePassword(false);
            setPasswordChanged(true);
        }).catch(error => {
            if (error.response.data.errors)
                setErrors(error.response.data.errors)
        })
    }

    const requestLogout = () => {
        API.post('/logout').then(response => {
            dispatch(logout());
        }).catch(err => {

        })
    }

    useEffect(() => {
        if (fetch) {
            setFetch(false);
            API.get(`/api/site_content/home`).then(response => {
                if (response.data.content) {
                    setComponent(response.data.content.component);
                }
                setLoading(false);
            }).catch(error => {

            });
        }
    }, [fetch])

    const makeSitePublic = () => {
        setSaving(true);
        API.post(`/api/site_content/home`, {
            component: {
                public: !component.public
            }
        }).then(response => {
            if (response.data.content) {
                setComponent(comp => ({
                    ...comp,
                    public:!component.public
                }))
            }
            setSaving(false);
        }).catch(error => {

        })
    }

    if (loading)
        return <div className="user-settings">
            <Loading />
        </div>

    return (
        <div className="user-settings">
            { saving &&
                <Loading
                    overlay
                    label={"Saving"}
                    icon={<FaSave />} />}
            { !changePassword && !passwordChanged &&
                <>

                    <button onClick={e => requestLogout()}>
                        Log out
                    </button>
                    <button onClick={e => makeSitePublic()}>
                        Make Site {component.public ? 'Private' : 'Public'}
                    </button>
                    <button onClick={e => setChangePassword(true)}>
                        Change Password
                    </button>
                </>
            }
            { passwordChanged &&
                <div className="success-message">
                    <h3>Password Successfully Changed</h3>
                    <button onClick={e => setPasswordChanged(false)}>
                        Ok
                    </button>
                </div>
            }
            { changePassword &&
                <div className="password-change">
                    <label>Current Password</label>
                    <input type="password"
                        onChange={handleChange}
                        value={passwordInfo.current_password}
                        name="current_password"
                        placeholder="Current password" />
                    {errors && errors.current_password &&
                        <label className="error">
                            {errors.current_password.join(' & ')}
                        </label>
                    }
                    <label>New Password</label>
                    <input type="password"
                        onChange={handleChange}
                        value={passwordInfo.new_password}
                        name="new_password"
                        placeholder="Current password" />
                    {errors && errors.new_password &&
                        <label className="error">
                            {errors.new_password.join(' & ')}
                        </label>
                    }
                    <label>Confirm New Password</label>
                    <input type="password"
                        onChange={handleChange}
                        value={passwordInfo.confirm_new_password}
                        name="confirm_new_password"
                        placeholder="Current password" />
                    {errors && errors.confirm_new_password &&
                        <label className="error">
                            {errors.confirm_new_password.join(' & ')}
                        </label>
                    }
                    <div className="btn-group">
                        <button onClick={e => sendChangePassword()}>
                            Change Password
                        </button>
                        <button onClick={e => setChangePassword(false)}>
                            Cancel
                        </button>
                    </div>
                </div>
            }
        </div>
    );
};

export default UserSettings;
