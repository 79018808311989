import React, { useState, useEffect, createRef } from "react";
import ImageEditor from "../ImageEditor";
import './index.css';

export interface ImageForm { 
    file: File,
    reference: React.RefObject<HTMLButtonElement>
}

const ImageUpload = () => {

    const [ images, setImages ] = useState<Array<ImageForm>>([]);    
    const [loading, setLoading ] = useState(false);

    useEffect(()=>{
        if(loading)
            setLoading(false);
    },[images,loading])


    const handleUpload = ( e : React.FormEvent<HTMLButtonElement>) => {
        if (!images) 
            return;
        images.forEach(image => {
            image?.reference?.current?.click();                 
        });            
    }

    const handleFile = (e:React.ChangeEvent<HTMLInputElement>) => {
        
        if (e.target.files === null) 
            return;
        
        setLoading(true);            
        setImages(images.concat(Array.from(e.target.files).filter(file => images.find(image => image.file.name === file.name) === undefined)
        .map((file, i ) => {
            return {
                file: file,
                reference: createRef()
            };
        })));        

        e.target.value = '';
    }

    return (
        <div className="image-upload">            
            <div className="header">
                <div className="header-section">
                    <h4>Add images:</h4>
                    <input  type="file"
                            multiple
                            placeholder="image"
                            onChange={handleFile} 
                            name="image" />
                </div>
                { images && images.length > 0 && 
                    <h4>
                        {images.length} image{images.length > 1 ? 's':''} selected
                    </h4>
                }
                <button type="submit" className="submit"
                        onClick={handleUpload}>Submit</button>
            </div>            
            <div className="images">
                {!loading && images && images.map((image, i) => 
                    <ImageEditor    file={image.file}
                                    key={image.file.name}     
                                    setImages={setImages}
                                    ref={image.reference}/>
                )}
            </div>                                       
        </div>
    );  
};

export default ImageUpload;
