import React, { useState } from "react";
import { useEffect } from "react";
import { FaPencilAlt, FaSave } from "react-icons/fa";
import { BsCardImage } from "react-icons/bs";
import { useAppSelector } from "../../store/hooks";
import { selectUser } from "../../store/reducers/user";
import API from "../../utils/API";
import AllImages, { AllImagesState, ImageInfo } from "../AllImages";
import AvatarEditor from 'react-avatar-editor'

import './index.css';
import { IoMdClose } from "react-icons/io";

import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css';
import Loading from "../Loading";

import { backend } from "../../utils/env";

interface Avatar {
	image: number,
	zoom: number
	rotate: number
	x: number
	y: number
}

const avatarTemplate: Avatar = {
	image: 0,
	zoom: 1.0,
	rotate: 0,
	x: 0,
	y: 0
}

interface component {
	content: string;
	avatar: Avatar;
}

const componentTemplate: component = {
	content: 'No content here',
	avatar: avatarTemplate
}

const About = () => {

	const [component, setComponent] = useState(componentTemplate);
	const [ogComponent, setOgComponent] = useState(componentTemplate);
	const [fetch, setFetch] = useState(true);
	const [loadedAvatar, setLoadedAvatar] = useState(false);
	const [editAvatar, setEditAvatar] = useState(false);
	const [editContent, setEditContent] = useState(false);
	const [image, setImage] = useState<string | null>(null);
	const [showImages, setShowImages] = useState<AllImagesState>(AllImagesState.hidden);
	const [loading, setLoading] = useState(true);
	const [saving, setSaving] = useState(false);
	const user = useAppSelector(selectUser);

	useEffect(() => {
		if (fetch) {
			setFetch(false);
			setLoading(true);
			API.get(`/api/site_content/about`, {
				withCredentials:false
			}).then(response => {
				if (response.data.content) {
					setComponent(response.data.content.component);
					setOgComponent(response.data.content.component);
					getProfilePicture(response.data.content.component.avatar.image);
				}

				setLoading(false);
			}).catch(error => {
				
			});
		}
	}, [fetch]);

	const cancelAvatar = () => {
		setComponent({
			...component,
			avatar: ogComponent.avatar
		})
	}

	const cancelContent = () => {
		setComponent({
			...component,
			content: ogComponent.content
		})
	}

	const onSave = () => {
		setSaving(true);
		API.post(`/api/site_content/about`, {
			component: component
		}).then(response => {
			if (response.data.content) {
				setEditAvatar(false);
				setShowImages(AllImagesState.hidden);
				setEditContent(false);
				setOgComponent(component)
			}
			setSaving(false);
		}).catch(error => {

		})
	}

	const getProfilePicture = (id: number) => {
		API.get(`/api/images/byID/${id}`, {
			withCredentials:false
		}).then(response => {
			console.log(response.data)
			if (response.data.image) {
				setImage(response.data.image.thumb_path)
			}
		}).catch(error => {

		})
	}

	const setProfilePicture = (image: ImageInfo) => {
		setComponent(component => ({
			...component,
			avatar: {
				...component.avatar,
				image: image.id
			}
		}));
		setImage(image.thumb_path);
		setLoadedAvatar(false);
		setShowImages(AllImagesState.hidden);
	}

	const handleAvatarChange = (e: React.ChangeEvent<HTMLInputElement>) => {

		let value = parseFloat(e.target.value);

		setComponent(component => ({
			...component,
			avatar: {
				...component.avatar,
				[e.target.name]: isNaN(value) ? e.target.value : value
			}
		}))
	}

	if (loading)
		return <Loading />

	return (
		<div className="about">
			{ saving &&
				<Loading
					overlay
					label={"Saving"}
					icon={<FaSave />} />}
			{ showImages !== AllImagesState.show && <>
				<div className="photo">
					<div className="photo-holder">
						{user && <>
							{editAvatar &&
								<button onClick={e => {
									setEditAvatar(false);
									cancelAvatar();
								}} className="edit-image cancel-edit">
									<IoMdClose />
								</button>}
							<div className="photo-actions">
								{editAvatar ? <>
									<button onClick={e => setShowImages(AllImagesState.peek)} className="edit-image">
										<BsCardImage />
									</button>
									<button onClick={e => onSave()} className="edit-image">
										<FaSave />
									</button>
								</> :
									<button onClick={e => setEditAvatar(true)} className="edit-image single">
										<FaPencilAlt />
									</button>
								}
							</div>
						</>}
						{!loadedAvatar &&
							<Loading color="#232b2b" />
						}
						{image && 
							<AvatarEditor
								image={`${backend}/${image}`}
								width={200}
								height={200}
								border={0}
								onLoadSuccess={(image) => {
									setLoadedAvatar(true);
								}}
								onLoadFailure={(e) => {
									console.log('Image not loaded');
									console.log('Waiting...')
									setTimeout(() => {
										console.log('The end')
									},5000)
									console.log(e);
								}}
								style={{
									cursor: !editAvatar ? 'default' : 'grab'
								}}
								color={[255, 255, 255, 0.6]} // RGBA
								scale={component.avatar.zoom}
								rotate={component.avatar.rotate}
								position={{ x: component.avatar.x, y: component.avatar.y }}								
								onPositionChange={({ x, y }) => {
									if (!user || !editAvatar)
										return;

									setComponent({
										...component,
										avatar: {
											...component.avatar,
											x: x,
											y: y
										}
									})
								}} />}
					</div>
					{editAvatar &&
						<div className="inputs">
							<label>Zoom:</label>
							<input type="range"
								step="0.01"
								min="1"
								max="2"
								name="zoom"
								value={component.avatar.zoom}
								onChange={handleAvatarChange} />
							<label>Rotation:</label>
							<input type="range"
								step="1"
								min="0"
								max="359"
								name="rotate"
								value={component.avatar.rotate}
								onChange={handleAvatarChange} />
						</div>}
				</div>
				<div className="about-content-wrapper">
					<div className="header">
						<h1>Ernesto Lozano Photography</h1>
					</div>
					<div className="about-content">
						<div className="background-border-holder">
							<div className="background-border"></div>
						</div>
						<div className="background"></div>
						<div className="the-content">
							<div className="content">
								{(editContent && user) ?
									<SunEditor name="content"
										setContents={component.content}
										setDefaultStyle="font-family:'Montserrat', sans-serif;color:#040410;"
										setOptions={{
											buttonList: [['undo', 'redo'],
											['bold', 'underline', 'italic'],
											['outdent', 'indent'],
											['fontColor', 'hiliteColor', 'fontSize']]
										}}
										onChange={content => {
											console.log(content)
											console.log(component)
											setComponent({
												...component,
												content: content
											})
										}} /> :
									<div dangerouslySetInnerHTML={{ __html: component.content }} />}
								{user &&
									<div className="content-actions">
										{!editContent ?
											<button className="content-action"
												onClick={e => setEditContent(true)}>
												<FaPencilAlt />
											</button> : <>
												<button className="content-action"
													onClick={e => onSave()}>
													<FaSave />
												</button>
												<button className="content-action"
													onClick={e => {
														setEditContent(false)
														cancelContent();
													}}>
													<IoMdClose />
												</button>
											</>
										}
									</div>}
							</div>
						</div>
					</div>
				</div>
			</>}
			{ (user && showImages !== AllImagesState.hidden) && <div className={`all-of-the-images ${showImages === AllImagesState.show ? 'on' : 'off'}`}>
				<AllImages onClicky={setProfilePicture}
					status={showImages}
					setStatus={setShowImages}
					actionMessage="Use as profile picture" />
			</div>}
		</div>
	);
};

export default About;
