import axios from "axios";
import { backend } from "./env";

axios.defaults.withCredentials = true;

const API = axios.create({
    baseURL: backend,
    withCredentials: true,
});

export default API;