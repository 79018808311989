import React from "react";

import MediaSlides, { Slide } from '../MediaSlides';

import { MdTimer, MdDirectionsBike } from 'react-icons/md';
import { GiPathDistance } from 'react-icons/gi';
import { FaHiking } from 'react-icons/fa';

import './index.css';

export enum ExperienceType {
    Hiking,
    Biking
}

interface ExperienceProps {
    slides:Array<Slide>;
    title:string,
    description:string,
    kind:ExperienceType 
};

const Experience = ({ slides,
		      title,
		      description,
		      kind }:ExperienceProps) => {
    return (
        <div className="experience">
            <div className="media-holder">
		<MediaSlides
                    className="media-preview"
                    slides={slides}/>
          </div>
          <div className="info">
            <h3>{title}</h3>
            <p>{description.substring(0,100)}</p>
	    <div className="stats">
		<div className="stat">
		    <MdTimer />: <span>3hrs</span>
		</div>
		<div className="stat">
		    <GiPathDistance />: <span>7km</span>
		</div>
		<div className="stat">
		    { kind === ExperienceType.Biking ?		      
		      <MdDirectionsBike />:
		      <FaHiking />
		    }
		</div>
	    </div>
          </div>
        </div>
    );  
};

export default Experience;
