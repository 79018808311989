import React, { useState } from "react";
import { StaticContext } from 'react-router';
import { Redirect, RouteComponentProps } from "react-router-dom";

import API from "../../utils/API";

import {
	useAppSelector,
	useAppDispatch
} from "../../store/hooks";

import {
	selectUser,
	login
} from "../../store/reducers/user";

import './index.css';

type LocationState = {
	from: Location;
};

interface LoginErrors {
	username?: Array<string>;
	password?: Array<string>;
}

const Login = (props: RouteComponentProps<{}, StaticContext, LocationState>) => {

	const user = useAppSelector(selectUser);
	const dispatch = useAppDispatch();

	const [username, setUsername] = useState("");
	const [password, setPassword] = useState("");

	const [errors, setErrors] = useState<LoginErrors>({})

	const loginRedirect = props?.location?.state?.from?.pathname ?
		props?.location?.state?.from?.pathname : '/about';
		
	if (user !== null)
		return <Redirect to={loginRedirect} />

	const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();

		API.get('/sanctum/csrf-cookie').then(response => {
			API.post('/login', {
				username: username,
				password: password
			}).then(res => {
				if (res.data)
					dispatch(login({
						name: res.data.user.name,
						username: res.data.user.username
					}))

			}).catch(e => {
				if (e.response.data.errors)
					setErrors(e.response.data.errors)
			})
		}).catch(err => {
			if (err.response.data.errors)
				setErrors(err.response.data.errors)
		});
	}

	const handleUsername = (e: React.ChangeEvent<HTMLInputElement>) => {
		setUsername(e.target.value);
	}

	const handlePassword = (e: React.ChangeEvent<HTMLInputElement>) => {
		setPassword(e.target.value);
	}

	return (
		<div className="login" >
			<form onSubmit={handleSubmit}
				className="login-form">
				<label htmlFor="username">Username</label>
				<input type="text" name="username" onChange={handleUsername} />
				{errors && errors.username &&
					<label className="error">
						{errors.username.join(' & ')}
					</label>
				}
				<label htmlFor="password">Password</label>
				<input type="password" name="password" onChange={handlePassword} />
				{errors && errors.password &&
					<label className="error">
						{errors.password.join(' & ')}
					</label>
				}
				<button type="submit" >Login</button>
			</form>
		</div>
	);
};

export default Login;
