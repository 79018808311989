import React from "react";
import API from "../../utils/API";

import './index.css';

const Lord = () => {

    const runOnAPI = (name : string) => {
        API.post(`/api/lord/${name}`).then(response => {
            console.log(response);
        }).catch(err => {

        })
    }

    return (
        <div className="lord">
            <button onClick={e => runOnAPI('migration')}>Run fresh migrations</button>
            <button onClick={e => runOnAPI('link')}>Create links</button>            
        </div>
    );  
};

export default Lord;
