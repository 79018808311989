import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";

import './index.css';

import { BiChevronRight, BiImageAdd, BiText } from "react-icons/bi"
import { animate, motion } from "framer-motion";
import AllImages, { AllImagesState, ImageInfo } from "../AllImages";
import FetchImage, { AvatarInfo, avatarTemplate } from "../FetchImage";
import { selectUser } from "../../store/reducers/user";
import { useAppSelector } from "../../store/hooks";
import { RiDeleteBin5Line, RiDeleteBin6Line } from 'react-icons/ri';
import { BsDot } from "react-icons/bs"
import { HiSwitchHorizontal } from "react-icons/hi"
import { FaImage, FaImages, FaPencilAlt, FaPlus, FaSave } from "react-icons/fa";
import { IoCloseSharp } from "react-icons/io5";
import { AiOutlineCaretLeft, AiOutlineCaretRight, AiOutlineExpand, AiOutlineExpandAlt, AiOutlineShrink } from "react-icons/ai";
import API from "../../utils/API";
import useWindowDimensions from "../../utils/Window";
import SunEditor from "suneditor-react";
import { GiCardDiscard } from "react-icons/gi";
import Loading from "../Loading";



interface PackageSectionType {
    image?: number | null;
    content?: string | null;
    imageFirst?: boolean;
    imageBackground?: boolean;
    info_location?: 'left' | 'middle' | 'right';
    avatar?: AvatarInfo;
}

interface PackageSectionProps {
    edit: boolean;
    index: number | 'new';
    image?: number | null;
    content?: string | null;
    imageFirst?: boolean;
    imageBackground?: boolean;
    info_location?: 'left' | 'middle' | 'right'
    avatar?: AvatarInfo;
    removeSection: (index: number) => void;
    newPackageSection: () => void;
    setPackageSection: (index: number, setter: (section: PackageSectionType) => PackageSectionType) => void;
}

export type PackageType = {
    title: string,
    title_id: string,
    id: number | 'new',
    description: string | null,
    image: number | null,
    content: Array<PackageSectionType> | null,
    info_location: 'left' | 'middle' | 'right',
    avatar: AvatarInfo | null,
};

export const packageTemplate: PackageType = {
    title: '',
    title_id: 'new',
    id: 'new',
    description: '',
    image: null,
    avatar: avatarTemplate,
    content: null,
    info_location: 'left'
}

type PackageProps = {
    pack: PackageType
    setPackages: (f: (packages: Array<PackageType>) => Array<PackageType>) => void
};

enum PackageState {
    entry = 'entry',
    single = 'single',
    hidden = 'hidden'
}

const Package = ({ pack, setPackages }: PackageProps) => {

    const [hovered, setHovered] = useState(false);
    const [edit, setEdit] = useState(pack.id === 'new' ? true : false);
    const [saved, setSaved] = useState(true);
    const [saving, setSaving] = useState(false);
    const [discardChanges, setDiscardChanges] = useState(false);
    const [confirmRemove, setConfirmRemove] = useState(false);
    const [loading, setLoading] = useState((pack.id === 'new' || pack.image === null) ? false : true);

    const [packageInfoFull, setPackageInfoFull] = useState(pack.id === 'new' ? true : false);
    const [state, setState] = useState<PackageState>(PackageState.entry);
    const [showImages, setShowImages] = useState<AllImagesState>(AllImagesState.hidden)

    const [ogPack, setOgPack] = useState<PackageType>(pack);

    const user = useAppSelector(selectUser);
    const location = useLocation();
    const history = useHistory();
    const { width } = useWindowDimensions();

    const [height, setHeight] = useState(300);

    const mobile = width < 827

    useEffect(() => {
        if (location.pathname === `/packages/all`) {
            setState(PackageState.entry);
            setEdit(false);
            setPackageInfoFull(false);
            setConfirmRemove(false)
        } else if (location.pathname === `/packages/${pack.title_id}`)
            setState(PackageState.single)
        else {
            setState(PackageState.hidden)
            setEdit(false);
            setPackageInfoFull(false);
            setConfirmRemove(false)
        }
    }, [location.pathname, pack])

    useEffect(() => {

        const controls = animate(state === PackageState.single && !edit ? 300 : 230,
            state === PackageState.single && !edit ? 230 : 300, {
            duration: 0.5,
            ease: 'linear',
            onUpdate: (latest: number) => {
                setHeight(latest);
            }
        })

        return controls.stop

    }, [state, edit])

    const addImageToContent = (image: ImageInfo) => {
        setPackages(packages => packages.map(p => p.id === pack.id ? ({
            ...p,
            image: image.id
        }) : p))
        setShowImages(AllImagesState.hidden)
    }

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setPackages(packages =>
            packages.map(packie => packie.id === pack.id ? ({
                ...packie,
                [e.target.name]: e.target.value
            }) : packie))
        setSaved(false);
    }

    const handleEditorChange = (name: string, value: string) => {
        setPackages(packages =>
            packages.map(packie => packie.id === pack.id ? ({
                ...packie,
                [name]: value
            }) : packie))
        setSaved(false);
    }

    const savePackage = () => {
        if (!user)
            return;
        setSaved(true);
        API.post(`/api/packages/${pack.id === 'new' ? 'new' : `${pack.id}/edit`}`,
            pack).then((response) => {
                if (response.data.success) {
                    setSaved(true);
                    setPackageInfoFull(false);
                    setEdit(false);
                    if (response.data.package)
                        setPackages(packages => packages.map(p => p.id === pack.id ?
                            response.data.package : p));
                    if (pack.id === 'new' || ogPack.title !== response.data.package.title)
                        history.replace(`/packages/${response.data.package.title_id}`);

                    setOgPack(pack);
                }
                setSaving(false);
            }).catch(error => {

            })
    }

    const setAvatar = (setter: (current: AvatarInfo) => AvatarInfo) => {
        setPackages(packages => packages.map(p => p.id === pack.id ? ({
            ...p,
            avatar: p.avatar ? setter(p.avatar) : setter(avatarTemplate)
        }) : p))
        setSaved(false);
    }

    const setPackageSection = (index: number, setter: (current: PackageSectionType) => PackageSectionType) => {
        setPackages(packages =>
            packages.map(p =>
                p.id === pack.id ?
                    ({
                        ...p,
                        content: p.content ?
                            p.content.map((content, i) =>
                                i === index ? setter(content) : content)
                            : p.content
                    }) :
                    p
            )
        )
        setSaved(false);
    }

    const newPackageSection = () => {
        setPackages(packages => packages.map(p => p.id === pack.id ? ({
            ...p,
            content: p.content ? [...p.content, {}] : [{}]
        }) : p))
        setSaved(false);
    }

    const removeSection = (index: number) => {
        setPackages(packages => packages.map(p => p.id === pack.id ? ({
            ...p,
            content: p.content ? p.content.filter((c, i) => i !== index) : null
        }) : p))
        setSaved(false);
    }

    const removePackage = () => {
        API.post(`/api/packages/${pack.id}/remove`).then(response => {
            if (response.data.success) {
                setPackages(packages => packages.filter(p => p.id !== pack.id))
                history.replace('packages/all');
            }
        })
    }

    const editorOptions = {
        mode: 'balloon-always',
        defaultStyle: "font-family:'Montserrat', sans-serif; box-sizing:border-box; font-size:20px; color:#fff; background-color:#22222200;",
        width: 'auto',
        minWidth: '300px',
        maxwidth: '420px',
        height: '130px',
        maxCharCount: 100,
        buttonList: [
            ['bold', 'underline', 'italic',
                'fontColor', 'hiliteColor'],
        ]
    };

    const paragraph = {
        loading: {
            opacity: 0,
            height: 0,
            marginLeft: 0,
            transition: {
                duration: 0.5
            },
        },
        hidden: {
            height: 0,
            opacity: 0,
            marginLeft: 0,
            transition: {
                duration: 0.5
            },
        },
        visible: {
            height: 230,
            opacity: 1,
            marginLeft: 10,
            transition: {
                duration: 0.3
            },
        }
    }

    const packageInfo = {
        loading: {
            opacity: 0,
            backgroundColor: "#191f1fcc",
            color: "#fff",
            transition: {
                duration: 0.5
            },
        },
        hidden: {
            opacity: 1,
            backgroundColor: "#191f1fcc",
            color: "#fff",
            transition: {
                duration: 0.5
            },
        },
        visible: {
            opacity: 1,
            backgroundColor: "#fff",
            color: "#000",
            transition: {
                duration: 0.3,
                opacity: { duration: 0.1 }
            },
        }
    }

    const overlay = {
        loading: {
            opacity: 0,
            transition: {
                duration: 0.5
            },
        },
        hidden: {
            opacity: 0,
            transition: {
                duration: 0.5
            },
        },
        visible: {
            opacity: 0.68,
            transition: {
                duration: 0.3
            },
        }
    }

    const fillerLeft = {
        loading: {
            opacity: 0,
            width: "0%",
            transition: {
                duration: 0.5
            },
        },
        hidden: {
            opacity: 1,
            width: pack.info_location === 'middle' ? "25%" :
                pack.info_location === 'right' ? "50%" : "0%",
            transition: {
                duration: edit ? 0.5 : 1.2
            },
        },
        visible: {
            opacity: 0,
            width: "0%",
            transition: {
                duration: 0.5
            },
        }
    }

    const fillerRight = {
        loading: {
            opacity: 0,
            width: "0%",
            transition: {
                duration: 0.5
            },
        },
        hidden: {
            opacity: 1,
            width: pack.info_location === 'middle' ? "25%" :
                pack.info_location === 'left' ? "50%" : "0%",
            transition: {
                duration: edit ? 0.5 : 1.2
            },
        },
        visible: {
            opacity: 0,
            width: "0%",
            transition: {
                duration: 0.5
            },
        },
    }

    const explore = {
        loading: {
            opacity: 0,
            width: "0%",
            transition: {
                duration: 0.5
            },
        },
        hidden: {
            opacity: 0,
            width: "0%",
            transition: {
                duration: 0.5
            },
        },
        visible: {
            opacity: 1,
            width: "50%",
            transition: {
                duration: 0.3
            },
        }
    }

    const packageVariants = {
        loading: {

        },
        hidden: {

        },
        visible: {

        }
    }

    const discardAllChanges = () => {
        setEdit(false)
        setPackageInfoFull(false);
        if (pack.id === 'new') {
            setPackages(packages => packages.filter(p => p.id !== 'new'))
            history.replace('/packages/all')
        } else
            setPackages(packages => packages.map(p => p.id === pack.id ? ogPack : p))
    }

    if (state === PackageState.hidden)
        return <></>;

    return (
        <motion.div
            className={`package-wrapper ${state} ${edit ? "edit" : ''}`}
            layout='position'>
            { saving &&
                <Loading
                    overlay
                    label={"Saving"}
                    icon={<FaSave />} />
            }

            { state === PackageState.single && user &&
                <div className="edit-actions sticky">
                    {edit ? <>
                        <button
                            onClick={e => !saved && savePackage()}
                            className={`edit-package ${saved ? 'disabled' : ''}`}>
                            <FaSave />
                        </button>
                        {pack.id !== 'new' && !confirmRemove &&
                            <button
                                onClick={e => setConfirmRemove(true)}
                                className={`edit-package`}>
                                <RiDeleteBin5Line />
                            </button>
                        }
                        {confirmRemove &&
                            <div className="edit-action-group">
                                <button
                                    onClick={e => {
                                        removePackage();
                                    }}
                                    className="edit-package label">
                                    <RiDeleteBin6Line /> <span>Delete this package</span>
                                </button>
                                <button
                                    onClick={e => {
                                        setConfirmRemove(false)
                                    }}
                                    className="edit-package label">
                                    <BsDot /> <span>No, keep it</span>
                                </button>
                            </div>
                        }
                        {!discardChanges &&
                            <button
                                onClick={e => {
                                    if (!saved)
                                        setDiscardChanges(true);
                                    else {
                                        discardAllChanges();
                                    }
                                }}
                                className="edit-package">
                                <IoCloseSharp />
                            </button>
                        }
                        {discardChanges &&
                            <div className="edit-action-group">
                                <button
                                    onClick={e => {
                                        discardAllChanges()
                                        setDiscardChanges(false)
                                    }}
                                    className="edit-package label">
                                    <GiCardDiscard /> <span>Discard my changes</span>
                                </button>
                                <button
                                    onClick={e => {
                                        setDiscardChanges(false);
                                    }}
                                    className="edit-package label">
                                    <BsDot /> <span>No, keep them</span>
                                </button>
                            </div>
                        }
                    </> :
                        <button onClick={e => setEdit(true)}
                            className="edit-package">
                            <FaPencilAlt />
                        </button>
                    }
                </div>
            }
            <div className="package-handle"
                onClick={e => state === PackageState.entry && history.push(`/packages/${pack.title_id}`)}
                onMouseEnter={e => setHovered(true)}
                onMouseLeave={e => setHovered(false)} >
                <motion.div
                    className={`package`}
                    initial="loading"
                    variants={packageVariants}
                    animate={
                        loading ? 'loading' :
                            (hovered && state === PackageState.entry) ?
                                'visible' :
                                'hidden'
                    } >
                    {edit &&
                        <div className="edit-actions image-change">
                            <button
                                onClick={e => setShowImages(AllImagesState.peek)}
                                className={`edit-package `}>
                                <FaImage />
                            </button>
                        </div>
                    }
                    {!mobile &&
                        <motion.div
                            variants={fillerLeft}
                            animate={(hovered && state === PackageState.entry) ?
                                'visible' :
                                'hidden'}
                            className="filler" />
                    }
                    {pack.info_location === 'left' &&
                        <motion.div variants={explore}
                            className="explore">
                            <BiChevronRight />
                            Explore
                        </motion.div>
                    }
                    <motion.div className="package-info"
                        variants={packageInfo} >
                        {edit && <>
                            {pack.info_location !== 'right' &&
                                <div className="move-wrapper right">
                                    <button onClick={e => pack.info_location === 'middle' ?
                                        handleEditorChange('info_location', 'right') :
                                        handleEditorChange('info_location', 'middle')}
                                        className="move">
                                        <AiOutlineCaretRight />
                                    </button>
                                </div>}
                            {pack.info_location !== 'left' &&
                                <div className="move-wrapper left">
                                    <button onClick={e => pack.info_location === 'middle' ?
                                        handleEditorChange('info_location', 'left') :
                                        handleEditorChange('info_location', 'middle')}
                                        className="move left">
                                        <AiOutlineCaretLeft />
                                    </button>
                                </div>
                            }
                            {packageInfoFull ?
                                <button onClick={e => setPackageInfoFull(false)}
                                    className="package-info-action">
                                    <AiOutlineShrink />
                                </button>
                                :
                                <button onClick={e => setPackageInfoFull(true)}
                                    className="package-info-action">
                                    <AiOutlineExpand />
                                </button>}
                        </>}
                        {edit ?
                            <input type="text"
                                name="title"
                                value={pack.title}
                                onChange={handleChange}
                                placeholder="Title" /> :
                            <h1>{pack.title}</h1>
                        }
                        <motion.div initial='hidden'
                            animate={
                                loading ? 'loading' :
                                    ((hovered &&
                                        state === PackageState.entry) ||
                                        packageInfoFull) ?
                                        'visible' :
                                        'hidden'
                            }
                            className="package-info-content"
                            variants={paragraph}>
                            {(edit && packageInfoFull) ?
                                <SunEditor name="description"
                                    setOptions={editorOptions}
                                    defaultValue={pack.description ? pack.description : undefined}
                                    placeholder="Describe the package"
                                    onChange={content => handleEditorChange('description', content)} /> :
                                (pack.description &&
                                    <div dangerouslySetInnerHTML={{ __html: pack.description }} />
                                )
                            }
                        </motion.div>
                    </motion.div>
                    {pack.info_location !== 'left' &&
                        <motion.div variants={explore}
                            className="explore">
                            <BiChevronRight />
                            Explore
                        </motion.div>
                    }
                    {!mobile &&
                        <motion.div variants={fillerRight}
                            animate={(hovered && state === PackageState.entry) ?
                                'visible' :
                                'hidden'}
                            className="filler" />
                    }
                    <div className="package-background">
                        <motion.div variants={overlay}
                            className="overlay" />
                        {pack.image &&
                            <FetchImage id={pack.image}
                                width={width - (mobile ? 50 : 300)}
                                height={height}
                                asAvatar
                                onLoad={() => setLoading(false)}
                                onError={() => setLoading(false)}
                                edit={edit}
                                setAvatar={setAvatar}
                                avatar={pack.avatar} />
                        }
                    </div>
                </motion.div>
            </div>
            { state === PackageState.single &&
                <div className="contents">
                    {pack.content && pack.content.map((content, i) =>
                        <PackageSection image={content.image}
                            key={i}
                            index={i}
                            edit={edit}
                            avatar={content.avatar}
                            imageBackground={content.imageBackground}
                            info_location={content.info_location}
                            content={content.content}
                            imageFirst={content.imageFirst}
                            removeSection={removeSection}
                            newPackageSection={newPackageSection}
                            setPackageSection={setPackageSection} />)}
                    {edit && <PackageSection
                        index={'new'}
                        edit={edit}
                        removeSection={removeSection}
                        newPackageSection={newPackageSection}
                        setPackageSection={setPackageSection} />}
                </div>
            }
            <AllImages status={showImages}
                setStatus={setShowImages}
                onClicky={addImageToContent}
                actionMessage="Add to background" />
        </motion.div>
    );
};

const PackageSection = ({
    edit,
    index,
    setPackageSection,
    newPackageSection,
    removeSection,
    image = null,
    content = null,
    imageFirst = false,
    imageBackground = false,
    info_location = 'left',
    avatar = avatarTemplate,
}: PackageSectionProps) => {

    const [showImages, setShowImages] = useState<AllImagesState>(AllImagesState.hidden);

    const [confirmDelete, setConfirmDelete] = useState(false);

    const user = useAppSelector(selectUser);
    const { width } = useWindowDimensions();

    const editorOptions = {
        mode: 'balloon-always',
        defaultStyle: "font-family:'Montserrat', sans-serif; box-sizing:border-box; font-size:20px; color:#fff; background-color:#22222200;",
        width: 'auto',
        minWidth: '300px',
        maxwidth: '400px',
        height: 'auto',
        maxHeight: '400',
        maxCharCount: 430,
        buttonList: [
            ['bold', 'underline', 'italic',
                'fontColor', 'hiliteColor'],
        ]
    }

    const setImage = (image: ImageInfo) => {

        if (index === 'new')
            return;

        setPackageSection(index, section => ({
            ...section,
            image: image.id
        }))

        setShowImages(AllImagesState.hidden);
    }

    const setAvatar = (setter: (current: AvatarInfo) => AvatarInfo) => {

        if (index === 'new')
            return;

        setPackageSection(index, section => ({
            ...section,
            avatar: setter(section.avatar ? section.avatar : avatarTemplate)
        }))
    }

    if (index === 'new')
        return <div className="package-section">
            <button type="button"
                className="new-section"
                onClick={e => newPackageSection()}>
                <FaPlus />
                <span>Add Section</span>
            </button>
        </div>

    return (
        <div className={`package-section ${imageFirst && !imageBackground ? 'reverse' : ''} ${imageBackground ? info_location : ''}`}>
            { edit && image && content && !imageBackground &&
                <div className="divider">
                    <div className="divider-line" />
                    <button className="divider-switch"
                        onClick={e => setPackageSection(index, section => ({
                            ...section,
                            imageFirst: !imageFirst
                        }))}>
                        <HiSwitchHorizontal />
                    </button>
                </div>
            }

            { showImages !== AllImagesState.hidden &&
                <AllImages status={showImages}
                    setStatus={setShowImages}
                    actionMessage="Add to section"
                    onClicky={setImage} />}

            { user &&
                <div className="edit-actions">
                    {edit &&
                        <>

                            {!content &&
                                <button className="edit-package light"
                                    onClick={e => setPackageSection(index, section => ({
                                        ...section,
                                        content: 'New content'
                                    }))}>
                                    <BiText />
                                </button>
                            }
                            {!image &&
                                <button className="edit-package light"
                                    onClick={e => setShowImages(AllImagesState.peek)}>
                                    <BiImageAdd />
                                </button>
                            }
                            {confirmDelete ?
                                <div className="edit-action-group">
                                    <button
                                        onClick={e => {
                                            removeSection(index);
                                            setConfirmDelete(false);
                                        }}
                                        className="edit-package label">
                                        <RiDeleteBin6Line /> <span>Remove this section</span>
                                    </button>
                                    <button
                                        onClick={e => {
                                            setConfirmDelete(false)
                                        }}
                                        className="edit-package label">
                                        <BsDot /> <span>No, keep it</span>
                                    </button>
                                </div>
                                :
                                <button className="edit-package"
                                    onClick={e => setConfirmDelete(true)}>
                                    <RiDeleteBin5Line />
                                </button>
                            }
                        </>
                    }
                </div>
            }
            { image &&
                <div className={`package-image ${imageBackground ? 'image-background' : ''}`}>
                    {edit &&
                        <div className="actions-section">
                            <button className="action-remove"
                                onClick={e => setPackageSection(index, section => ({
                                    ...section,
                                    imageBackground: !section.imageBackground
                                }))}>
                                {imageBackground ?
                                    <AiOutlineShrink />
                                    :
                                    <AiOutlineExpandAlt />}
                            </button>
                            <button className="action-remove"
                                onClick={e => setPackageSection(index, section => ({
                                    ...section,
                                    image: null
                                }))}>
                                <RiDeleteBin5Line />
                            </button>
                            <button className="action-remove"
                                onClick={e => setShowImages(AllImagesState.peek)} >
                                <FaImages />
                            </button>
                        </div>}

                    <FetchImage asAvatar={imageBackground}
                        setAvatar={setAvatar}
                        edit={edit}
                        width={width - 300}
                        height={500}
                        avatar={avatar}
                        id={image} />
                </div>
            }

            { content &&
                <div className={`package-text ${imageBackground ? 'with-background' : ''}`}>
                    {edit ?
                        <>
                            {imageBackground && info_location !== 'right' &&
                                <div className="move-wrapper right">
                                    <button onClick={e =>
                                        setPackageSection(index, section => ({
                                            ...section,
                                            info_location: section.info_location === 'middle' ? 'right' : 'middle'
                                        }))}
                                        className="move">
                                        <AiOutlineCaretRight />
                                    </button>
                                </div>}
                            {imageBackground && info_location !== 'left' &&
                                <div className="move-wrapper left">
                                    <button onClick={e =>
                                        setPackageSection(index, section => ({
                                            ...section,
                                            info_location: section.info_location === 'middle' ? 'left' : 'middle'
                                        }))}
                                        className="move left">
                                        <AiOutlineCaretLeft />
                                    </button>
                                </div>
                            }
                            <div className="actions-section">
                                <button className="action-remove"
                                    onClick={e => setPackageSection(index, section => ({
                                        ...section,
                                        content: null
                                    }))}>
                                    <RiDeleteBin5Line />
                                </button>
                            </div>
                            <SunEditor name="content"
                                setOptions={editorOptions}
                                onChange={value => setPackageSection(index, section => ({
                                    ...section,
                                    content: value
                                }))}
                                defaultValue={content} />
                        </> :
                        <div dangerouslySetInnerHTML={{ __html: content }} />}
                </div>
            }
        </div>)
}

export default Package;
