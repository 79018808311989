import React, { useState } from "react";
import { Link } from "react-router-dom";
import { IoRemoveCircleSharp, IoCheckmarkCircleSharp, } from "react-icons/io5"
import { MdCancel } from "react-icons/md";
import './index.css';
import { backend } from "../../utils/env";

interface ImageInfo {
    id: number;
    title: string;
    title_id: string;
    og_path: string;
    thumb_path: string;
}

type ImageProps = {
    image: ImageInfo;
    title?: string | null;
    entryId?: string | null;
    edit?: boolean;
    removeEvent?: ((id: number) => void) | null;
}

const Image = ({ image, title = null, entryId = null, edit = false,
removeEvent = null}: ImageProps) => {
    const [removeConfirm, setRemoveConfirm] = useState(false)

    return (
        <>
            <Link to={`/portfolio/${entryId}/${image.title_id}`}
                className="image">
                <img src={`${backend}/${image.thumb_path}`} alt={image.title} />
                <div className="info">
                    <p>{image.title}</p>
                </div>
            </Link>
            { ( edit && removeEvent ) && <>
                { !removeConfirm ?
                    <button className="portfolio-action float"
                        onClick={e => setRemoveConfirm(true)}>
                        <IoRemoveCircleSharp />
                    </button> :
                    <div className="portfolio-remove-action">
                        <h3>Remove {image.title} from portfolio? </h3>
                        <div className="portfolio-remove-actions">
                            <button className="portfolio-actiony"
                                    onClick={e => removeEvent(image.id)}>
                                <IoCheckmarkCircleSharp />
                            </button>
                            <button className="portfolio-actiony"
                                onClick={e => setRemoveConfirm(false)}>
                                <MdCancel />
                            </button>
                        </div>
                    </div>}

            </>}
        </>
    );
};

export default Image;
